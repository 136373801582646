import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import { Header } from '@hz-design-system/web-ui';

export default () => {
  // eslint-disable-next-line no-underscore-dangle
  const headerConfig = window.__HEADER_CONFIG__ || {};
  const headerRoot = document.getElementById('header-root');

  // Header is server side rendered in express-react library
  if (headerRoot) {
    hydrateRoot(headerRoot, <Header headerConfig={headerConfig} />);
  }
};
